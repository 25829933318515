import React from 'react';
import Main from 'layouts/Main';
import { FourZeroFourSection } from './components';
import { useTheme } from '@mui/material/styles';

export const FourZeroFourView = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Main bgcolor={theme.palette.background.level1}>
      <FourZeroFourSection />
    </Main>
  );
};
