import React from 'react';
import FourZeroFour from 'views/FourZeroFour';
import { SEO as Head } from 'components/seo';

const FourZeroFourPage = (): JSX.Element => {
  return (
    <>
      <Head />
      <FourZeroFour />
    </>
  );
};

export default FourZeroFourPage;
