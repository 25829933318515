import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { StyledPropsFix } from 'types';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Button } from '@mui/material';
import Pic404 from 'images/404.svg';
import { Divider } from 'utils';
import { SiteMaxWithContext } from 'theme/siteMaxWithContext';

const ContentBox = styled(Container)``;
const Title = styled(Typography)`` as StyledPropsFix;
const SubTitle = styled(Typography)`` as StyledPropsFix;
const Text = styled(Typography)`` as StyledPropsFix;
const ActionButton = styled(Button)`` as StyledPropsFix;
const FourZeroFourPic = styled(Box)<{ src: string }>`
  background-image: url(${({ src }) => src});
`;

const FourZeroFourSectionStyles = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  line-height: 34px !important;

  .fourZeroFour-contentBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 1em;

    ${({ theme }) => theme.breakpoints.down('md')} {
      padding: 0 2em;
      margin: 0;
    }
  }
  .fourZeroFour-title {
    font-family: Poppins SemiBold;
    color: ${({ theme }) => theme.palette.text.primary};
    padding-bottom: 0.5em;
    font-size: 3.1em;

    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 2.4em;
    }
  }
  .fourZeroFour-subTitle {
    font-family: Roboto Bold;
    line-height: 34px;
    color: ${({ theme }) => theme.palette.text.primary};

    font-size: 2.2em;

    ${({ theme }) => theme.breakpoints.down('md')} {
      padding-bottom: 0;
      font-size: 1.8em;
    }
    ${({ theme }) => theme.breakpoints.only('xs')} {
      line-height: 28px;
    }
  }
  .fourZeroFour-text {
    font-family: Roboto Bold;
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: 1.5em;
    padding-bottom: 1em;
    line-height: 34px;

    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 1.1em;
      padding-top: 1em;
    }

    ${({ theme }) => theme.breakpoints.only('xs')} {
      line-height: 28px;
    }
  }
  .fourZeroFour-picture {
    aspect-ratio: 848/397;
    background-size: contain;
    width: 100%;
    height: 100%;
  }
  .fourZeroFour-actionButton {
    text-transform: none;
    font-family: Roboto Regular;
    color: ${({ theme }) => theme.palette.text.secondary};
    width: min-content;
    align-self: center;
    white-space: nowrap;

    &:hover {
      background-color: #58a88f;
    }

    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 1.125em;
      margin: 0;
      transform: scale(0.7);
    }
  }
`;

const FourZeroFourSection = (): JSX.Element => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const [sizeOfGrid] = React.useContext(SiteMaxWithContext);

  return (
    <FourZeroFourSectionStyles>
      <Box
        sx={{ backgroundColor: theme.palette.background.paper }}
        maxWidth="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Divider height={isXs ? '3.3em' : '6.3em'} />
        <ContentBox
          className="fourZeroFour-contentBox"
          sx={{ maxWidth: `${sizeOfGrid}px` }}
        >
          <Title component="h1" className="fourZeroFour-title">
            Oops!
          </Title>
          <SubTitle component="h2" className="fourZeroFour-subTitle">
            Something went wrong.
          </SubTitle>
          <Divider height={isXs ? '1em' : '2.3em'} />
          <Text component="p" className="fourZeroFour-text">
            Error 404 Page not Found.
          </Text>
          <ActionButton
            href="/"
            variant="contained"
            color="alternate"
            className="fourZeroFour-actionButton"
          >
            Back to home
          </ActionButton>

          <Divider height={isXs ? '1em' : '2.3em'} />

          <FourZeroFourPic src={Pic404} className="fourZeroFour-picture" />
        </ContentBox>
        <Divider height={isXs ? '5.3em' : '10.3em'} />
      </Box>
    </FourZeroFourSectionStyles>
  );
};

export default FourZeroFourSection;
